* {
  box-sizing: border-box;
}

body {
  font-family: "IBM Plex Sans", sans-serif;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

a {
  text-decoration: none;
}

h1 strong {
  color: #FAB4FF;
  text-shadow: rgba(255, 255, 255, 0.2) 0 0 5px;
}

p a {
  text-decoration: underline dotted 1px;
}

a,
a:visited {
  color: inherit;
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}