.card {
  width: 100%;
  height: 100%;
  min-height: 512px;
  background: linear-gradient(
    180deg,
    rgba(145, 70, 255, 1) 15%,
    rgba(0, 200, 175, 1) 125%
  );
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 512px) {
  .card {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
  }
}

.header {
  display: flex;
  padding: 48px;
  justify-content: space-between;
  align-items: center;
  color: #f0f0ff;
}

.logo {
  font-weight: bold;
  font-size: 1.5em;
  vertical-align: middle;
  transition: opacity 0.05s ease;
}

.logo img {
  width: 64px;
  height: 64px;
  vertical-align: middle;
}

.logo:hover {
  opacity: 0.75;
}

.social {
  display: flex;
}

.social a {
  display: inline-block;
  margin-right: 12px;
  transition: opacity 0.05s ease;
}

.social a:last-child {
  margin-right: 0;
}

.social a:hover {
  opacity: 0.5;
}

.social .icon {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  fill: #f0f0ff;
}

.content {
  flex: 1 1 auto;
  min-height: 256px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content .title-holder {
  color: #f0f0ff;
  text-align: center;
  margin-bottom: 24px;
}

.content .title-holder h1 {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 12px;
}

.content .title-holder p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  color: #f0f0ff;
}

@media screen and (max-width: 768px) {
  .content .title-holder {
    max-width: 80%;
  }
}

.content .cta {
  min-width: 64px;
  padding: 16px 24px;
  background: #d2d2e6;
  border-radius: 50px;
  cursor: pointer;
  color: #3f3f3f;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  transform: none;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.25, 0.315, 1.35),
    transform 0.1s linear;
}

.content .cta:hover {
  transform: translateY(-1px);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
}

.container button {
  min-width: 64px;
  padding: 4px;
  background: #d2d2e6;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  color: #3f3f3f;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  transform: none;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.25, 0.315, 1.35),
    transform 0.1s linear;
}

.container button:hover {
  transform: translateY(-1px);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
}

.waitlist {
  flex-direction: row;
  padding: 48px;
  justify-content: center;
  margin-bottom: 12px;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
  color: #f0f0ff;
  text-align: center;
}

.waitlist strong{
  color: #FAB4FF;
  text-shadow: rgba(255, 255, 255, 0.2) 0 0 5px;
  
}
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.placeholder {
  color:rgba(0, 0, 0, 0);
}

.break {
  flex-basis: 100%;
  height: 0;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 48px;
  justify-content: center;
  color: #f0f0ff;
  opacity: 0.75;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

a.underlined:hover {
  border-bottom: 1px dotted #f0f0ff;
}

.fade {
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
